import React from 'react'
import SEO from "../../components/seo"
import DoctorOrderFlow from '../../components/DoctorOrderFlow'
import Layout from '../../components/layout'
import '../../components/DoctorOrderFlow/style.scss'

const TrialBoxes = () => {
  return (
    <Layout isDoctorPortal={true}>
      <SEO title="Doctor Portal" />
         <DoctorOrderFlow currentFormTab={"TRIAL_ORDER"}/>
    </Layout>
  )
}

export default TrialBoxes;
